var Datepicker;
var Datepicker_Locale;

(function()
{
	function validRegex(expr, str)
	{
		var result												= null;

		try
		{
			var regexp											= new RegExp(expr, "g");
			result												= regexp.exec(str);
		}
		catch (ex)												{ /* no problem */ }

		return result != null && result[0] == str;
	}

	function buildCalendar(picker)
	{
		var $main												= $('<div class="datepicker calendar unselectable" style="display: none;"></div>');

		var $header												= $('<div class="datepicker-header"></div>');
		var $left												= $('<div style="float: left; padding: 2px 8px; font-size: 1.25em;"><i class="fa fa-chevron-left"></i></div>');
		var $right												= $('<div style="float: right; padding: 2px 8px; font-size: 1.25em;"><i class="fa fa-chevron-right"></i></div>');
		var $title												= $('<div style="text-align: center; font-weight: bold;"></div>');
		var $monthInput											= $('<select tabindex="-1"></select>');
		var $yearInput											= $('<select tabindex="-1"></select>');

		$title.append($monthInput);
		$title.append(' ');
		$title.append($yearInput);

		$header.append($right);
		$header.append($left);
		$header.append($title);

		$header.append('<div style="clear: both;"></div>');

		var cols												= '';
		for (var i = 0; i < 8; i = i + 1)						{ cols = cols + '<col style="width: 47px;" />'; }

		var $table												= $('<table><colgroup>' + cols + '</colgroup></table>');
		var $tbody												= $('<tbody></tbody>');

		var $row;

		$row													= $('<tr></tr>');

		var i;

		var weekdaysShort										= moment.weekdaysMin(true);

		for (i = 0; i < weekdaysShort.length; i = i + 1)
		{
			$row.append($('<th></th>').text(weekdaysShort[i]));
		}

		var monthnames											= moment.months();

		for (i = 0; i < monthnames.length; i = i + 1)
		{
			$monthInput.append($('<option></option>').text(monthnames[i]).val(i));
		}

		$table.append($row);

		$table.append($tbody);

		var rendering											= false;
		var oldYear, oldMinYear, oldMaxYear; // Cached for performance (IE)

		var render = function()
		{
			rendering = true;

			try
			{
				$monthInput.val(picker.period.month());

				if (oldYear != picker.period.year())
				{
					var minYear									= Math.min(picker.period.year() - 5, moment().year() - 75);
					var maxYear									= Math.max(picker.period.year() + 15, moment().year() + 1);

					if (picker.minDate)							{ minYear = Math.max(minYear, picker.minDate.year()); }
					if (picker.maxDate)							{ maxYear = Math.min(maxYear, picker.maxDate.year() + 1); }

					if (oldMinYear != minYear || oldMaxYear != maxYear)
					{
						var options								= '';

						for (var i = minYear; i < maxYear; i = i + 1)
						{
							options								= options + '<option value="' + i + '">' + i + '</option>';
						}

						$yearInput.html(options);

						oldMinYear								= minYear;
						oldMaxYear								= maxYear;
					}

					oldYear										= picker.period.year();
				}

				$yearInput.val(picker.period.year());

				$tbody.empty();

				var year										= picker.period.year();
				var month										= picker.period.month() + 1;
				month											= month < 10 ? '0' + month : month;
				var $row;

				var minDate										= moment(year + '-' + month + '-01', 'YYYY-MM-DD').startOf('week');
				var maxDate										= moment(year + '-' + month + '-01', 'YYYY-MM-DD').endOf('month').endOf('week');
				var diff										= Math.max(41, maxDate.diff(minDate, 'days'));

				$row											= null;

				var m, $col;

				for (i = 0; i <= diff; i = i + 1)
				{
					if ((i % 7) == 0)
					{
						if ($row)								{ $tbody.append($row); }

						$row									= $('<tr></tr>');
					}

					m											= minDate.clone().add(i, 'days');

					$col										= $('<td></td>').attr('data-value', m.format('YYYY-MM-DD HH:mm:ss')).text(m.format('D'));

					if (!m.isSame(picker.period, 'month'))		{ $col.addClass('other'); }

					if (picker.value && m.isSame(picker.value, 'day'))
					{
						$col.addClass('active');
					}

					if ((picker.minDate && m.isBefore(picker.minDate)) || (picker.maxDate && m.isAfter(picker.maxDate)))
					{
						$col.addClass('disabled');
					}

					$row.append($col);
				}

				if ($row && $row.find('td').length > 0)			{ $tbody.append($row); }
			}
			finally												{ rendering = false; }
		}

		$main.append($header);
		$main.append($table);

		var $hourInput, $minuteInput, $secondInput;
		var addZero = function(i)
		{
			if (i < 10)											{ return '0' + i; }
			else												{ return i; }
		};

		if (picker.showTime)
		{
			var m												= picker.value || moment();

			if (!picker.showSeconds)							{ m.second(0); }

			var $timeDiv										= $('<div class="time"><i class=\"fa fa-clock-o\"></i>&nbsp;&nbsp;&nbsp;</div>');
			$hourInput											= $('<input type="text" tabindex="-1" class=\"hour\" />').val(addZero(m.hour()));
			$minuteInput										= $('<input type="text" tabindex="-1" class=\"minute\" />').val(addZero(m.minute()));
			$secondInput										= $('<input type="text" tabindex="-1" class=\"second\" />').val(addZero(m.second()));

			$timeDiv.append($hourInput);
			$timeDiv.append(' : ');
			$timeDiv.append($minuteInput);
			if (picker.showSeconds)
			{
				$timeDiv.append(' : ');
				$timeDiv.append($secondInput);
			}

			var selectAll = function(e)
			{
				this.value = this.value;
				this.select();
			};

			var mouseWheel = function(f, e)
			{
				e.preventDefault();

				if (e.originalEvent.wheelDelta != 0)
				{
					var v										= parseInt(this.value);
					var max										= (f == 'hour' ? 23 : 59);

					if (v !== null && v !== undefined && v >= 0 && v <= max && picker.value)
					{
						if (e.originalEvent.wheelDelta > 0)		{ v++; } // up
						else									{ v--; } // down

						if		(v > max)						{ v = 0; }
						else if (v < 0)							{ v = max; }

						this.value								= addZero(v);

						$main.data('value', picker.value.clone()[f](v));
						$main.trigger('change');

						this.focus();
						this.select();
					}
				}
			}

			$hourInput.on('keyup', function()
			{
				var v											= parseInt(this.value);

				if (v !== null && v !== undefined && v >= 0 && v <= 23 && picker.value)
				{
					$main.data('value', picker.value.clone().hour(v));
					$main.trigger('change');
				}
			}).on('mousewheel', mouseWheel.bind($hourInput[0], 'hour')).on('mouseup', selectAll);

			$minuteInput.on('keyup', function()
			{
				var v											= parseInt(this.value);

				if (v && v >= 0 && v <= 59 && picker.value)
				{
					$main.data('value', picker.value.clone().minute(v));
					$main.trigger('change');
				}
			}).on('mousewheel', mouseWheel.bind($minuteInput[0], 'minute')).on('mouseup', selectAll);

			if (picker.showSeconds)
			{
				$secondInput.on('keyup', function()
				{
					var v											= parseInt(this.value);

					if (v && v >= 0 && v <= 60 /* 60 to account for leap seconds */ && picker.value)
					{
						$main.data('value', picker.value.clone().second(v));
						$main.trigger('change');
					}
				}).on('mousewheel', mouseWheel.bind($secondInput[0], 'second')).on('mouseup', selectAll);
			}

			$timeDiv.append($('<span class="datepicker-close-button" style="position: absolute; right: 4px;">&times;</span>').on('click', picker.hide));

			$main.append($timeDiv);
		}

		$table.on('click', 'td', function()
		{
			$table.find('td.active').removeClass('active');
			$(this).addClass('active');

			var m													= moment($(this).attr('data-value'));

			if (picker.showTime)
			{
				var hour											= parseInt($hourInput.val());
				var minute											= parseInt($minuteInput.val());
				var second											= parseInt($secondInput.val());

				if (hour >= 0 && hour <= 23)
				{
					m.hour($hourInput.val())
					$hourInput.val(addZero(hour));
				}

				if (minute >= 0 && minute <= 59)
				{
					m.minute(minute);
					$minuteInput.val(addZero(minute));
				}

				if (second >= 0 && second <= 60 /* 60 to account for leap seconds */)
				{
					m.second(second);
					$secondInput.val(addZero(second));
				}
			}

			$main.data('value', m);
			$main.trigger('change');

			if (!picker.showTime)
			{
				picker.hide();
			}
		});

		$left.css('cursor', 'pointer').on('click', function()
		{
			picker.period = picker.period.subtract(1, 'month');
			render();
		});

		$right.css('cursor', 'pointer').on('click', function()
		{
			picker.period = picker.period.add(1, 'month');
			render();
		});

		$yearInput.on('change', function()
		{
			if (!rendering)
			{
				picker.period.year(this.value);
				render();
			}
		});

		$monthInput.on('change', function()
		{
			if (!rendering)
			{
				picker.period.month(this.value);
				render();
			}
		});

		$main.on('picker.show', function()
		{
			picker.period = (picker.value || moment()).clone().startOf('month');
			render();
		});

		render();

		return $main;
	}

	Datepicker = function(element, options)
	{
		var picker												= this;
		var skipChanges											= 0;
		var $element											= $(element);

		this.originalType										= $element.attr('type');

		$element.attr('type', 'hidden');

		var $displayElement										= $('<input type="text" maxlength=\"' + (options.enableTime ? (options.showSeconds ? 19 : 16) : 10) + '\" />');

		$displayElement.attr('class', $element.attr('class'));
		$element.removeAttr('class');

		if ($element.attr('readonly'))							{ $displayElement.attr('readonly', 'readonly'); }
		if ($element.attr('disabled'))							{ $displayElement.attr('disabled', 'disabled'); }

		$displayElement.insertAfter($element);

		var $calendarElement;

		this.displayElement										= $displayElement[0];
		this.element											= $element[0];
		this.displayFormat										= options.enableTime ? (options.showSeconds ? Datepicker_Locale.displayFormatWithTimeWS : Datepicker_Locale.displayFormatWithTime) : Datepicker_Locale.displayFormat;
		this.format												= options.enableTime ? 'YYYY-MM-DDTHH:mm:ss' : 'YYYY-MM-DD'; // note: this format is automatically supported by AngularJS
		this.value												= moment($element.val(), this.format);
		this.valid												= this.value && this.value.isValid();
		this.value												= this.valid ? this.value : null;
		this.period												= (this.value || moment()).clone().startOf('month');
		this.showTime											= options.enableTime;
		this.showSeconds										= options.showSeconds;
		this.minDate											= options.minDate;
		this.maxDate											= options.maxDate;

		this.show = function()
		{
			if (!($element.attr('readonly') || $element.attr('disabled')))
			{
				$calendarElement.trigger('picker.show');
				$calendarElement.show();
			}
		};

		this.hide = function()
		{
			$calendarElement.trigger('picker.hide');
			$calendarElement.hide();
		};

		var setValue = function(initial)
		{
			// Ensure the value is between the bounds of minDate/maxDate.
			if (picker.valid)
			{
				if		(picker.minDate && picker.value.isBefore(picker.minDate))
				{
					picker.value								= picker.minDate.clone();
				}
				else if (picker.maxDate && picker.value.isAfter(picker.maxDate))
				{
					picker.value								= picker.maxDate.clone();
				}
			}

			if (picker.valid)
			{
				$element.val(picker.value.format(picker.format));
				$displayElement.val(picker.value.format(picker.displayFormat));
				$displayElement.data('oldValue', $displayElement.val());
			}
			else												{ $element.val(''); }

			// Do not send a change event on the initial modification. Prevents AngularJS from deciding the form has been changed.
			if (!initial)										{ skipChanges++; $element.trigger('change'); }
		};

		$element.on('change', function()
		{
			if (--skipChanges < 0)
			{
				skipChanges										= 0;

				var value										= moment($(this).val(), picker.format);

				if (value)
				{
					picker.value								= moment(value, picker.displayFormat, true);
					picker.valid								= picker.value && picker.value.isValid();

					setValue();
				}
			}
		});

		var getCaretPosition = function(elem)
		{
			// Initialize
			var iCaretPos										= 0;

			// IE Support
			if (document.selection)
			{
				// Set focus on the element
				elem.focus();

				// To get cursor position, get empty selection range
				var oSel = document.selection.createRange();

				// Move selection start to 0 position
				oSel.moveStart('character', -elem.value.length);

				// The caret position is selection length
				iCaretPos = oSel.text.length;
			}

			// Firefox support
			else if (elem.selectionStart || elem.selectionStart == '0')
			{
				iCaretPos = elem.selectionStart;
			}

			return iCaretPos;
		};

		var setCaretPosition = function(elem, caretPos)
		{
			if (elem != null)
			{
				if (elem.createTextRange)
				{
					var range									= elem.createTextRange();
					range.move('character', caretPos);
					range.select();
				}
				else
				{
					if(elem.selectionStart)
					{
						elem.focus();
						elem.setSelectionRange(caretPos, caretPos);
					}
					else
					{
						elem.focus();
					}
				}
			}
		};

		$displayElement.on('keyup', function(e)
		{
			if ($(this).data('oldValueKeyUp') != this.value)
			{
				$(this).data('oldValueKeyUp', this.value);

				var pos											= getCaretPosition(this);

				this.value										= $.trim(this.value);
				picker.value									= moment(this.value, picker.displayFormat, true);
				picker.valid									= picker.value && picker.value.isValid();

				if (!picker.valid)
				{
					picker.value								= moment(this.value, picker.displayFormat.replace(/MM/, 'M'), true);
					picker.valid								= picker.value && picker.value.isValid();
				}

				if (!picker.valid)
				{
					picker.value								= moment(this.value, picker.displayFormat.replace(/DD/, 'D'), true);
					picker.valid								= picker.value && picker.value.isValid();
				}

				if (!picker.valid)
				{
					picker.value								= moment(this.value, picker.displayFormat.replace(/DD/, 'D').replace(/MM/, 'M'), true);
					picker.valid								= picker.value && picker.value.isValid();
				}

				if (!picker.valid)								{ picker.value = null; }

				if (picker.value)								{ $element.val(picker.value.format(picker.format)); }
				else											{ $element.val(''); }

				skipChanges++;
				$element.trigger('change');

				// Update the picker when a new value is typed.
				if (picker.valid)								{ $calendarElement.trigger('picker.show'); }

				setCaretPosition(this, pos);
			}
		});

		$displayElement.on('blur', function(e)
		{
			if ($(this).data('oldValue') != this.value)
			{
				$(this).data('oldValue', this.value);

				var v											= $.trim(this.value);
				picker.value									= moment(v, picker.displayFormat, true);
				picker.valid									= picker.value && picker.value.isValid();

				if (!picker.valid)
				{
					picker.value								= moment(v, picker.displayFormat.replace(/MM/, 'M'), true);
					picker.valid								= picker.value && picker.value.isValid();
				}

				if (!picker.valid)
				{
					picker.value								= moment(v, picker.displayFormat.replace(/DD/, 'D'), true);
					picker.valid								= picker.value && picker.value.isValid();
				}

				if (!picker.valid)
				{
					picker.value								= moment(v, picker.displayFormat.replace(/DD/, 'D').replace(/MM/, 'M'), true);
					picker.valid								= picker.value && picker.value.isValid();
				}

				if (!picker.valid)								{ picker.value = null; }

				setValue();

				picker.hide();
			}
		});

		$displayElement.data('oldValue', $displayElement.val());

		$displayElement.on('focus', picker.show);

		$calendarElement										= buildCalendar(picker);

		$calendarElement.on('change', function()
		{
			picker.value										= $calendarElement.data('value');
			picker.valid										= picker.value && picker.value.isValid();
			if (!picker.valid)									{ picker.value = null; }

			setValue();
		});

		$calendarElement.insertAfter($displayElement);

		setValue(true);

		$(document).on('click', function(e)
		{
			if (!(e.target == $displayElement[0] || $(e.target).is('.datepicker.calendar') || $(e.target).parents('.datepicker.calendar').length > 0))
			{
				picker.hide();
			}
		});

		$(document).on('focus', '*', function(e)
		{
			if (!(e.target == $displayElement[0] || $(e.target).is('.datepicker.calendar') || $(e.target).parents('.datepicker.calendar').length > 0))
			{
				picker.hide();
			}
		});
	};

	Datepicker.prototype.destroy = function()
	{
		$(this.element).attr('type', this.originalType).attr('class', $(this.displayElement).attr('class'));

		$(this.displayElement).remove();
	};
})();

(function()
{
	var selector												= 'input[type=date], input[type=datetime], input[type=datetime-local]';

	var initDatepickerField = function(element)
	{
		var $element											= $(element);

		if (!$element.data('datepicker'))
		{
			var type											= $element.attr('type');

			var minDate											= $element.attr('min');
			var maxDate											= $element.attr('max');
			var defaultDate										= $element.attr('default');

			var options											= {};

			if (type == 'datetime' || type == 'datetime-local')	{ options.enableTime = true; }

			if (minDate)										{ options['minDate'] = minDate.toMoment(true, 'down').local(); }
			if (maxDate)										{ options['maxDate'] = maxDate.toMoment(true, 'up').local(); }

			$element.data('datepicker', new Datepicker($element[0], options));
		}
	};

	var destroyDatepickerField = function(element)
	{
		var $element											= $(element);

		if ($element.data('datepicker'))
		{
			var p												= $element.data('datepicker');

			$element.data('datepicker', null);

			p.destroy();
		}

		$element.css('display', '');
	}

	$(window).on('load', function()
	{
		$(selector).each(function()
		{
			initDatepickerField(this);
		});

		var observer = new MutationObserver(function(mutations)
		{
			var $element;
			var j;

			for (var i = 0; i < mutations.length; i = i + 1)
			{
				if (mutations[i].addedNodes && mutations[i].addedNodes.length > 0)
				{
					for (j = 0; j < mutations[i].addedNodes.length; j = j + 1)
					{
						$element								= $(mutations[i].addedNodes[j]);

						if (mutations[i].addedNodes[j].tagName == 'INPUT')
						{

							if ($element.is(selector))
							{
								initDatepickerField($element[0]);
							}
						}
						else
						{
							$element.find(selector).each(function(){ initDatepickerField(this); });
						}
					}
				}

				if (mutations[i].removedNodes && mutations[i].removedNodes.length > 0)
				{
					for (j = 0; j < mutations[i].removedNodes.length; j = j + 1)
					{
						$element								= $(mutations[i].removedNodes[j]);

						if ($element.data('datepicker'))		{ destroyDatepickerField($element[0]); }
						else
						{
							$element.find('input').each(function()
							{
								if ($(this).data('datepicker'))
								{
									destroyDatepickerField(this);
								}
							})
						}
					}
				}
			}
		});

		observer.observe(document, { childList: true, subtree: true });
	})
})();


Datepicker_Locale = {
	'displayFormat': 'DD-MM-YYYY',
	'displayFormatWithTime': 'DD-MM-YYYY HH:mm',
	'displayFormatWithTimeWS': 'DD-MM-YYYY HH:mm:ss'
};

